<template>
    <div class="user page">
        <Nav></Nav>
        <div class="wrap" style="padding-top: 10px; min-height: 700px">
            <a-layout>
                <a-layout-sider>
                    <Menu></Menu>
                </a-layout-sider>
                <a-layout>
                    <a-layout-header>
                        <a-row type="flex" justify="center" align="middle" class="top">
                            <a-col :span="6">
                                <icon-font type="icon-bonuspoints" /> 我寄的
                            </a-col>
                        </a-row>
                    </a-layout-header>
                    <a-layout-content>
                        <a-row :gutter="[16, 16]" class="waybill-list">
                            <a-col :span="12" v-for="(item, index) in list" :key="index">
                                <a-card :title="`运单号：${item.number}`" :hoverable="true">
                                    <router-link slot="extra" :to="`/WaybillDetail?id=${item.number}`">运单详情
                                        <a-icon type="right" />
                                    </router-link>
                                    <div class="waybill-info">
                                        <div class="post-city-user">
                                            <div class="city">
                                                {{ item.senderProvinceName }}
                                            </div>
                                            <div class="user">
                                                {{ item.senderName }}
                                            </div>
                                        </div>
                                        <div class="state">
                                            {{ item.waybillStateName }}
                                        </div>
                                        <div class="receive-city-user">
                                            <div class="city">
                                                {{ item.receiverProvinceName }}
                                            </div>
                                            <div class="user">
                                                {{ item.receiverName }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="state u-flex">
                                        <div style="width: 91%">
                                            {{ item.waybillStateName }}：{{ item.waybillStateName }}
                                        </div>
                                    </div>
                                    <div class="time u-flex" style="border-top: 1px solid #e4e7ed">
                                        <div class="timeitem">
                                            {{ item.waybillStateName }}时间：{{ item.waybillStateTime }}
                                        </div>
                                        <div class="price">运费：{{ item.payAmount }}</div>
                                    </div>
                                    <div class="state u-flex">
                                        <div style="width: 11%"></div>
                                        <div v class="u-flex" style="justify-content: flex-end;width: 100%;">
                                            <a-button style="color: red;margin-left: 10px;"
                                                v-if="item.waybillStateId == 1 && item.mailingTypeId == 1" type="link"
                                                @click="showModal(item.number)">
                                                <a-icon type="plus-circle" />加快递
                                            </a-button>
                                            <a-button type="primary" style="margin-left: 10px;"
                                                v-if="item.mailingTypeId == 2 && item.ownprint == 1"
                                                @click="down(item.number)">
                                                <a-icon type="printer" />下载/打印条码
                                            </a-button>
                                            <a-button type="danger" style="margin-left: 10px;"
                                                v-if="item.waybillStateId == 2 && item.isReceivables == 0"
                                                @click="gopay(item.number)">
                                                <a-icon type="pay-circle" />去支付
                                            </a-button>
                                            <a-button type="danger" style="margin-left: 10px;"
                                                v-if="item.waybillStateId == 1 || item.waybillStateId == 2"
                                                @click="cacel(item.number)">
                                                <a-icon type="close-circle" />取消
                                            </a-button>

                                            <a-button type="danger" style="margin-left: 10px;"
                                                v-if="item.waybillStateId == 6" @click="deleteo(item.number)">
                                                <a-icon type="close-circle" />删除
                                            </a-button>
                                        </div>
                                    </div>
                                </a-card>
                            </a-col>
                            <div v-if="list.length == 0" syle="padding-top: 20px">
                                <a-empty description="暂无数据" />
                            </div>
                        </a-row>
                    </a-layout-content>
                </a-layout>
            </a-layout>
        </div>
        <Foot></Foot>
        <a-modal v-model="visible" title="绑定快递单" :footer="null">
            <a-input v-model="postCode" addon-before="快递单号" placeholder="请填写所寄物品快递单号">
                <a-icon slot="addonAfter" type="plus-circle" @click="addPostCode" />
            </a-input>
            <a-layout>
                <a-layout-header>
                    <a-col :span="12"> <a-icon type="check" /> 已绑定快递单 </a-col>
                </a-layout-header>
                <a-layout-content>
                    <a-list item-layout="horizontal" :data-source="data">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <a-list-item-meta>
                                <a slot="title">{{ item.postCode }}</a>
                            </a-list-item-meta>
                            <a slot="actions" @click="del(item.postCode)">删除</a>
                        </a-list-item>
                    </a-list>
                </a-layout-content>
            </a-layout>
        </a-modal>
    </div>
</template>

<script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
    components: {
        Nav,
        Foot,
        Menu,
        IconFont,
    },
    data() {
        return {
            user: {},
            list: [],
            visible: false,
            orderNo: "",
            data: [],
            postCode: "",
        };
    },
    computed: {},
    created() {
        if (this.$route.name === "Home") this.main_swiper = true;
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchDate",
    },
    mounted() {
        this.user = this.storage.getItem("user");
        if (!this.user) {
            this.getUserInfo();
        }
        this.loadData();
    },
    methods: {
        fetchDate() {
            if (this.$route.name !== "Home") {
                this.main_swiper = false;
            } else {
                this.main_swiper = true;
            }
        },
        getUserInfo() {
            this.api
                .getuserDetail({})
                .then((res) => {
                    this.storage.setItem("user", res);
                    this.user = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        loadData() {
            this.api
                .getmywaybill()
                .then((res) => {
                    this.list = res.send;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        down(number) {
            this.$router.push("/viewbarCode?id=" + number);
        },
        gopay(number) {
            this.$router.push('/WaybillPaymethod?id=' + number);
        },
        showModal(number) {
            this.orderNo = number;
            this.data = [];
            this.postCode = "";
            this.bindexpress();
            this.visible = true;
        },
        bindexpress() {
            this.api
                .waybillBindPostCodeList({
                    waybillNumber: this.orderNo,
                })
                .then((res) => {
                    this.data = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        cacel(number) {
            var that = this;
            this.$confirm({
                title: "提示",
                content: "确认取消?",
                onOk() {
                    that.api
                        .waybillCacel({
                            orderNo: number,
                        })
                        .then((res) => {
                            if (res.errCode == 0) {
                                that.$message.success("取消成功", 10);
                                that.loadData();
                            } else {
                                that.$message.error(res.errMsg, 10);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test",
            });
        },
        deleteo(number) {
            var that = this;
            this.$confirm({
                title: "提示",
                content: "确认取消?",
                onOk() {
                    that.api
                        .waybillDelete({
                            orderNo: number,
                        })
                        .then((res) => {
                            if (res.errCode == 0) {
                                that.$message.success("删除成功", 10);
                                that.loadData();
                            } else {
                                that.$message.error(res.errMsg, 10);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test",
            });
        },
        addPostCode() {
            if (this.postCode == "" || this.postCode.length < 8) {
                this.codeFocus = true;
                return this.$message.error("请填写正确的快递单号码", 10);
            }
            this.api
                .waybillBindPostCode({
                    waybillNumber: this.orderNo,
                    postCode: this.postCode,
                })
                .then((res) => {
                    if (res.errCode == 0) {
                        this.postCode = "";
                        this.$message.success("添加成功", 10);
                        this.bindexpress();
                    } else {
                        this.$message.error(res.errMsg, 10);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        del(e) {
            var that = this;
            this.$confirm({
                title: "提示",
                content: "确认删除?",
                onOk() {
                    that.api
                        .deleteWaybillBindPostCode({
                            postCode: e,
                        })
                        .then((res) => {
                            if (res.errCode == 0) {
                                that.$message.success("删除成功", 10);
                                that.bindexpress();
                            } else {
                                that.$message.error(res.errMsg, 10);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test",
            });
        },
    },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
    background: #f5f5f5;
}

.wrap {
    height: 100%;

    // padding-bottom: 20px;
}

.ant-layout {
    height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
    background: #ffffff !important;
    padding: 10px;
}

.ant-layout-sider {
    margin-right: 10px;
    text-align: center;
}

.ant-layout-content {
    margin-top: 10px;
}

.ant-layout-header {
    height: auto;

    .top {
        .ant-col {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .anticon {
            font-size: 24px;
            margin-right: 10px;
        }
    }
}

.user {
    .user-name {
        font-size: 14px;
        margin-top: 10px;
    }

    .other-info {
        text-align: left;
        margin-left: 30px;

        span {
            color: @text-color-secondary;
        }
    }
}

.menu {
    text-align: left;
    padding-left: 30px;
    line-height: 28px;
    margin-top: 30px;
    border-top: 1px solid #e2e2e2;

    dl {
        padding-top: 10px;

        dt {
            font-size: 16px;
            line-height: 28px;
        }

        dd {
            color: @text-color-secondary;
        }
    }
}

div.content-wrap {
    padding: 15px;
}

.num {
    color: red;
    padding-right: 10px;
}

.waybill-list {
    // padding: 15px;

    .ant-card-bordered {
        border-radius: 5px;
    }

    .state {
        padding-top: 10px;
        padding-bottom: 8px;
        height: 50px;
    }

    .time {
        padding-top: 5px;
        justify-content: space-between;

        .timeitem {
            color: #c8c9cc;
        }

        .price {
            color: #cf0022;
            font-size: 15px;
            font-weight: 600;
        }
    }
}

.waybill-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .city {
        font-size: 16px;
        font-weight: 600;
        line-height: 2;
    }

    .user {
        font-size: 14px;
        color: @text-color-secondary;
    }

    div {
        text-align: center;
    }
}
</style>